import React from "react";
import EventbritePreview from "../eventbrite-preview/eventbrite-preview.component";
import "./events-list.styles.scss";

// import eventsMocked from '../../mocks/events';

const EventsList = ({ events }) => {
  // console.log('==> events', events);
  // const eventsPlus = [...events, ...eventsMocked];

  // {eventsPlus && eventsPlus.map((event) => (

  return (
    <section className="section events-list is-medium">
      <div className="container">
        <div className="columns is-multiline">
          {events &&
            events.map((event) => (
              <EventbritePreview key={event.id} event={event} />
            ))}
        </div>
      </div>
    </section>
  );
};

export default EventsList;
