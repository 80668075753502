import React from "react";

import Layout from "../components/layout";
import EventsList from "../components/events-list/events-list.components";
import { respH } from "../lib/helpers";
import Seo from "../components/seo";
import NewsLetter from "../components/news-letter/news-letter.component";

import "./corsiPageTemplate.styles.scss";

const CorsiTemplate = ({ pageContext: { events } }) => {
  return (
    <Layout pageClass="corsi">
      <Seo
        title="Corsi"
        description="Corsi in programmazione e di prossima uscita."
      />
      <section className="hero is-large">
        <div className="hero-body">
          <div className="container is-max-desktop">
            <h1
              className={`title ${respH(
                1
              )} has-text-white has-text-centered mb-6`}
            >
              Corsi ed Eventi
            </h1>
          </div>
        </div>
      </section>
      {events.length > 0 ? (
        <EventsList events={events} />
      ) : (
        <section className="section is-medium">
          <div className="columns">
            <div className="column">
              <h1 className="title is-2 has-text-centered">
                Il calendario dei corsi è in aggiornamento...
              </h1>
            </div>
          </div>
        </section>
      )}
      <NewsLetter />
    </Layout>
  );
};

export default CorsiTemplate;
