import React from "react";
import { showDate } from "../../lib/helpers";
import "./eventbrite-preview.styles.scss";

const EventbritePreview = ({ event }) => {
  const title = event.name.text;
  const startDay = showDate(event.start.utc);
  // const endDay = showDate(event.end.utc);
  const startHour = showDate(event.start.utc, { minutes: true });
  const endHour = showDate(event.end.utc, { minutes: true });
  const posterUrl = event.logo && event.logo.url;
  const url = event.url;
  // const status = event.status;

  return (
    <div className="column is-one-quarter">
      <a
        href={url}
        className="card is-block is-relative"
        target="_blank"
        rel="noreferrer"
      >
        <div className="card-image">
          <figure className="image">
            <img src={posterUrl} alt={`Immagine per evento ${title}`} />
          </figure>
        </div>
        <div className="card-content">
          <div className="content">
            <h2 className="title is-4">{title}</h2>
            <p>{event.description.text}</p>
            <div className="block">
              <p>{startDay}</p>
              <p>
                Ore: {startHour} - {endHour}
              </p>
            </div>
          </div>
        </div>
        {/* <h1 className="ribbon"> */}
        {/*    <strong className="ribbon-content is-capitalized">{status}</strong> */}
        {/* </h1> */}
      </a>
    </div>
  );
};

export default EventbritePreview;
